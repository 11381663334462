<template>
  <loading
    v-model:active="is_loading"
    :can-cancel="false"
    :is-full-page="true"
  ></loading>
  <article class="message is-warning is-small" v-if="error_message">
    <div class="message-body">
      {{ error_message }}
    </div>
  </article>
  <div class="content">
    <ContentHeader title="宅配ボックス情報登録" />
    <LockerRegistFlow
      input_status="done"
      confirm_status="active"
      complete_status="active"
    />
    <div v-if="is_allow_action">
      <div class="qr-read">
        宅配ボックスを登録します。<br />
        カメラ起動を押して、ボックスに貼られたスマリQRコードをスキャンしてください。<br />
        <div class="btn-wrapper">
          <button class="button is-rounded btn-submit" @click="showModal">
            カメラ起動
          </button>
        </div>
      </div>
      <div v-if="is_show_input_form">
        <div class="locker-info">
          正常に宅配ボックス番号が読み込まれました。<br />
          ({{ locker.code }}&nbsp;&nbsp;{{ locker.name }})
        </div>
        住所を入力してください。
        <div class="spacer"></div>
        <div class="field flex-field">
          <div class="zip">
            <label class="label">郵便番号</label>
            <input
              type="text"
              class="amplify-input"
              v-model="locker_location_form.zip"
              placeholder="123-4567"
              disabled
            />
            <span v-if="validation_errors.zip" class="help is-danger">
              {{ validation_errors.zip[0] }}
            </span>
          </div>
          <div class="prefecture">
            <label class="label">都道府県 <span class="tag is-danger">必須</span></label>
            <select
              class="amplify-select pref-select"
              v-model="locker_location_form.prefecture"
            >
              <option v-for="(pref, index) in prefectures" :value="pref" :key="index">{{ pref }}</option>
            </select>
            <span v-if="validation_errors.prefecture" class="help is-danger">
              {{ validation_errors.prefecture[0] }}
            </span>
          </div>
        </div>
        <div class="field form-custom">
          <label class="label">市区町村 <span class="tag is-danger">必須</span></label>
          <input
            type="text"
            class="amplify-input"
            v-model="locker_location_form.city"
            placeholder="例：千代田区"
          />
          <span v-if="validation_errors.city" class="help is-danger">
            {{ validation_errors.city[0] }}
          </span>
        </div>
        <div class="field form-custom">
          <label class="label">町域名 <span class="tag is-danger">必須</span></label>
          <input
            type="text"
            class="amplify-input"
            v-model="locker_location_form.address"
            placeholder="例：丸の内"
          />
          <span v-if="validation_errors.address" class="help is-danger">
            {{ validation_errors.address[0] }}
          </span>
        </div>
        <div class="field form-custom">
          <label class="label">丁目番地 <span class="tag is-danger">必須</span></label>
          <input
            type="text"
            class="amplify-input"
            v-model="locker_location_form.address_no"
            placeholder="例：２ー３ー１"
          />
          <span v-if="validation_errors.address_no" class="help is-danger">
            {{ validation_errors.address_no[0] }}
          </span>
        </div>
        <div class="field form-custom">
          <label class="label">建物名・部屋番号</label>
          <input
            type="text"
            class="amplify-input"
            v-model="locker_location_form.address_ext"
            placeholder=""
          />
          <span v-if="validation_errors.address_ext" class="help is-danger">
            {{ validation_errors.address_ext[0] }}
          </span>
        </div>
        <div class="btn-wrapper">
          <button class="button is-rounded btn-submit" @click="confirmLocker">
            次へ
          </button>
        </div>
      </div>
    </div>
    <div
      class="modal"
      :class="{ 'is-active': is_show_modal }"
      v-if="is_show_modal"
    >
      <div class="modal-background"></div>
      <div class="modal-content">
        <qr-stream @decode="onDecode"></qr-stream>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="closeModal"
      ></button>
    </div>
  </div>
</template>
<script>
import { Auth } from "aws-amplify"
import { QrStream } from "vue3-qr-reader"
import LockerRepository from "../repositories/LockerRepository"
import Loading from "vue-loading-overlay"
import LockerRegistFlow from "../components/elements/LockerRegistFlow.vue"
import validate from "validate.js"
import GeoCodeService from "../services/GeoCodeService"
import ContentHeader from "../components/ContentHeader.vue"

export default {
  name: "LockerRegist",
  components: {
    QrStream,
    Loading,
    LockerRegistFlow,
    ContentHeader,
  },
  async beforeMount() {
    this.is_loading = true
    try {
      const user = await Auth.currentAuthenticatedUser()
      this.user.cognito_uuid = user.attributes.sub
      if (!user.attributes.locale) {
        this.error_message = "アカウントに郵便番号が登録されていません。アカウント情報変更から登録をお願いします。"
        return
      }
      this.locker_location_form.zip = this.addHyphenToZip(user.attributes.locale)
      const address_by_zip = await GeoCodeService.searchAdressByZip(user.attributes.locale)
      if (address_by_zip) {
        this.locker_location_form.prefecture = address_by_zip.prefecture
        this.locker_location_form.city = address_by_zip.city
        this.locker_location_form.address = address_by_zip.town_area
      }
      this.is_allow_action = true
    } catch (error) {
      this.error_message = "アカウント情報を取得できませんでした。"
      return
    } finally {
      this.is_loading = false
    }
  },
  data() {
    return {
      token: this.$route.query.token,
      is_allow_action: false,
      is_show_modal: false,
      is_loading: false,
      is_show_input_form: false,
      user: {
        cognito_uuid: "",
      },
      locker: {
        code: "",
        name: "",
      },
      locker_location_form: {
        zip: "",
        prefecture: "",
        city: "",
        address: "",
        address_no: "",
        address_ext: "",
      },
      validation_errors: {},
      error_message: "",
      prefectures: [
        "北海道",
        "青森県",
        "岩手県",
        "宮城県",
        "秋田県",
        "山形県",
        "福島県",
        "茨城県",
        "栃木県",
        "群馬県",
        "埼玉県",
        "千葉県",
        "東京都",
        "神奈川県",
        "新潟県",
        "富山県",
        "石川県",
        "福井県",
        "山梨県",
        "長野県",
        "岐阜県",
        "静岡県",
        "愛知県",
        "三重県",
        "滋賀県",
        "京都府",
        "大阪府",
        "兵庫県",
        "奈良県",
        "和歌山県",
        "鳥取県",
        "島根県",
        "岡山県",
        "広島県",
        "山口県",
        "徳島県",
        "香川県",
        "愛媛県",
        "高知県",
        "福岡県",
        "佐賀県",
        "長崎県",
        "熊本県",
        "大分県",
        "宮崎県",
        "鹿児島県",
        "沖縄県",
      ],
    }
  },
  methods: {
    validate(form_data) {
      const error_messages = {
        presence: "^この項目は必須です",
        valid_zip: "^有効な郵便番号を入力してください",
        inclusion: "^有効な値が選択されていません",
      }

      validate.validators.validZip = (value) => {
        const reg_exp = /^[0-9]{3}-[0-9]{4}$/
        if (reg_exp.test(value)) {
          return
        }
        return error_messages.valid_zip
      }

      const constraints = {
        zip: {
          presence: {
            allowEmpty: false,
            message: error_messages.presence,
          },
          validZip: true,
        },
        prefecture: {
          presence: {
            allowEmpty: false,
            message: error_messages.presence,
          },
          inclusion: {
            within: this.prefectures,
            message: error_messages.inclusion,
          },
        },
        city: {
          presence: {
            allowEmpty: false,
            message: error_messages.presence,
          },
        },
        address: {
          presence: {
            allowEmpty: false,
            message: error_messages.presence,
          },
        },
        address_no: {
          presence: {
            allowEmpty: false,
            message: error_messages.presence,
          },
        },
        address_ext: {},
      }
      const validate_result = validate(form_data, constraints)
      return validate_result
    },
    showModal() {
      this.is_show_modal = true
    },
    closeModal() {
      this.is_show_modal = false
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = "red"

        ctx.beginPath()
        ctx.moveTo(firstPoint.x, firstPoint.y)
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y)
        }
        ctx.lineTo(firstPoint.x, firstPoint.y)
        ctx.closePath()
        ctx.stroke()
      }
    },
    async onDecode(decode_string) {
      this.is_loading = true
      this.error_message = ""
      const decode_error_msg = "利用できないQRコードが読み込まれました。カメラ起動を押して正しいQRコードをスキャンしてください。"
      if (decode_string.substr(0, 3) !== "BSM") {
        this.error_message = decode_error_msg
        this.is_show_input_form = false
        this.is_loading = false
        this.closeModal()
        return
      }
      LockerRepository.findByCode(decode_string)
        .then((locker) => {
          if (locker.locker_location_id) {
            this.error_message = "この宅配ボックスは既に住所が登録されています。"
            this.is_show_input_form = false
            this.closeModal()
            return
          }
          this.is_show_input_form = true
          this.locker.code = locker.code
          this.locker.name = locker.name
          this.closeModal()
        })
        .catch(() => {
          this.is_show_input_form = false
          this.error_message = decode_error_msg
          return
        })
        .finally(() => {
          this.is_loading = false
        })
      this.closeModal()
    },
    async confirmLocker() {
      this.validation_errors = {}
      const validation_errors = this.validate(this.locker_location_form)
      if (validation_errors) {
        this.validation_errors = validation_errors
        return
      }
      this.$store.commit("setLocker", this.locker)
      this.$store.commit("setLockerLocation", this.locker_location_form)
      this.$store.commit("setUser", this.user)
      this.$router.push({
        path: "/locker/regist-confirm",
      })
    },
    addHyphenToZip(zip) {
      const first = zip.substr(0, 3)
      const second = zip.substr(3)
      return `${first}-${second}`
    },
  },
}
</script>

<style scoped>
.label {
  margin-bottom: 3px;
}
.btn-wrapper {
  padding-top: 10px;
  display: flex;
  justify-content: space-evenly;
}
.locker-info {
  padding-top: 10px;
  text-align: center;
  padding-bottom: 10px;
}
.spacer {
  padding: 5px;
}
.qr-read {
  margin-bottom: 10px;
}

.pref-select {
  appearance: auto;
}

.flex-field {
  display: flex;
  justify-content: space-between;
}

.flex-field .zip {
  width: 47%;
}
.flex-field .prefecture {
  width: 47%;
}
</style>
