import axios from "axios"

export default {
  searchAdressByZip: async (zip) => {
    const url = "https://maps.googleapis.com/maps/api/geocode/json"
    const params = {
      address: zip,
      lang: "ja",
      key: "AIzaSyBDdx6_eDM0cIL8NfHNcFgT1shXnBUTWjc",
    }
    const res = await axios.get(url, {
      params: {
        ...params,
      },
    })
    if (res.data.results.length === 0) {
      return null
    }
    const address_components = res.data.results[0].address_components
    // 都道府県
    const pref = extractAddress(
      address_components,
      "administrative_area_level_1"
    )
    // 群/村
    const group = extractAddress(
      address_components,
      "administrative_area_level_2"
    )
    // 市区町村
    const city = extractAddress(address_components, "locality")
    // 行政区
    const ward = extractAddress(address_components, "sublocality_level_1")
    // 町域
    const town_area = extractAddress(address_components, "sublocality_level_2")

    return {
      prefecture: pref,
      city: `${group}${city}${ward}`,
      town_area: town_area,
    }
  },
}
/**
 * GeoCodingの住所コンポーネントから特定のタイプを取り出す
 * @param {array} components
 * @param {string} type
 * @returns {string}
 */
function extractAddress(components, type) {
  let target = components.filter((component) => {
    return component.types.includes(type)
  })[0]?.long_name
  // 値が無いケースは空文字
  return target !== undefined ? target : ""
}
